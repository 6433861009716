import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveVideo,
  fetchPendingApprovalVideos, rejectVideo,
} from './actions';
import { getPendingApprovalVideos } from './selectors';
import {
  Alert,
  Button,
  Col,
  Form, Modal,
  Row,
} from 'react-bootstrap';
import VideoDisplay from '../../components/VideoDisplay';

const VideoApproval = ({ video, onApprove, onReject, disabled }) => {
  const { videoId } = video;
  return (
    <Col xs={12} sm={6} lg={4}>
      <Row className="m-0 my-3 py-3 border">
        <Col xs={12}>
          <VideoDisplay video={video} />
        </Col>
        <Col className="text-left pt-3">
          <Button disabled={disabled} onClick={() => onApprove(videoId)} data-cy={'approveVideo'}>
            Approve
          </Button>
        </Col>
        <Col className="text-right pt-3">
          <Button disabled={disabled} onClick={() => onReject(videoId)} data-cy={'rejectVideo'}>
            Reject
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

const RejectVideoModal = ({ show, onHide, onReject }) => {
  const [reason, setReason] = useState('');
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Reject Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Reason</Form.Label>
          <Form.Control type="text" name="reason" value={reason}
                        onChange={evt => setReason(evt.target.value)}
                        data-cy={'reason'} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onReject(reason)} data-cy={'reject'}>Reject</Button>
      </Modal.Footer>
    </Modal>
  );
};

const pageSize = 3;

const VideoApprovalPage = () => {
  const dispatch = useDispatch();
  const { fetching, results, total, error, posting } =
    useSelector(getPendingApprovalVideos);
  const [{ refreshTrigger, rejectVideoId, page }, setState] = useState({
    refreshTrigger: false,
    rejectVideoId: '',
    page: 1,
  });
  useEffect(() => { dispatch(fetchPendingApprovalVideos(page, pageSize)); },
    [dispatch, refreshTrigger, page, pageSize]);
  const handleRefresh = () =>
    setState(state => ({ ...state, refreshTrigger: !refreshTrigger }));
  const handleApprove = videoId => {
    dispatch(approveVideo(videoId));
    setState(state => ({
      ...state,
      refreshTrigger: !refreshTrigger,
    }));
  };
  const handleReject = videoId =>
    setState(state => ({ ...state, rejectVideoId: videoId }));
  const handleHide = () => setState(state => ({ ...state, rejectVideoId: '' }));
  const handleRejected = reason => {
    dispatch(rejectVideo(rejectVideoId, reason));
    setState(state => ({
      ...state,
      rejectVideoId: '',
      refreshTrigger: !refreshTrigger,
    }));
  };
  const disabled = fetching || posting;
  const prevPage = () => setState(state => ({ ...state, page: page - 1 }));
  const nextPage = () => setState(state => ({ ...state, page: page + 1 }));
  const isLastPage = (page * pageSize) >= total;
  return (
    <>
      <RejectVideoModal show={!!rejectVideoId} onHide={handleHide}
                        onReject={handleRejected} />
      <Row>
        <Col>
          <Alert hidden={!error} variant="danger">{error && error}</Alert>
          <Button onClick={handleRefresh} disabled={disabled} className="my-3" data-cy={'adminVideoRefreshButton'}>
            Refresh
          </Button>
        </Col>
      </Row>
      <Row data-cy={'pendingVideos'}>
        {results.map(video =>
          <VideoApproval key={video.videoId} video={video} disabled={disabled}
                         onApprove={handleApprove} onReject={handleReject}/>
        )}
      </Row>
      <Row>
        <Col className="p-3 clearfix">
          {<Button className="float-left" disabled={page <= 1}
                   onClick={prevPage} data-cy={'previousPage'}>&lt;</Button>}
          {<Button className="float-right" disabled={isLastPage}
                   onClick={nextPage} data-cy={'nextPage'}>&gt;</Button>}
        </Col>
      </Row>
    </>
  );
};

export default VideoApprovalPage;
