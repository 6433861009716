import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingReport } from './selectors';
import { FormControl, Table, Row, Col, Button } from 'react-bootstrap';
import { fetchBillingReport, downloadBillingReport } from './actions';
import Paginator from '../../components/Paginator';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const DateDisplay = ({ date }) => {
  const parts = date.split('-');
  const dt = new Date(parts[0], parseInt(parts[1],10)-1, parts[2]);
  const month = months[dt.getMonth()];
  return <span>
    {month}&nbsp;{dt.getDate()},&nbsp;{dt.getFullYear()}
  </span>;
};

const BillingReportPage = () => {
  const dispatch = useDispatch();
  const { results: billingReportRows, total, loading, error } = useSelector(getBillingReport);
  const [{ page, pageSize, fromDate, toDate }, setState] = useState({
    page: 1,
    pageSize: 100,
    fromDate: '',
    toDate: '',
  });
  useEffect(() => {
    if (!fromDate || !toDate) return;
    dispatch(fetchBillingReport(fromDate, toDate, page, pageSize));
  }, [page, pageSize, fromDate, toDate]);
  const handleFromDateChange = (evt) => {
    const fromDate = evt.target.value;
    setState(state => ({ ...state, fromDate, page: 1 }));
  };
  const handleToDateChange = (evt) => {
    const toDate = evt.target.value;
    setState(state => ({ ...state, toDate, page: 1 }));
  };
  const handlePageChange = page => setState(state => ({ ...state, page }));
  const handleExportToCSV = () => {
    if (!fromDate) return;
    dispatch(downloadBillingReport(fromDate, toDate));
  };
  return (
    <section data-cy={'billingReportPage'}>
      <Row>
        <Col xs={4}>
          <FormControl type="date" title="From date"
                       value={fromDate} onChange={handleFromDateChange}
                       data-cy={'fromDate'} />
        </Col>
        <Col xs={4}>
          <FormControl type="date" title="To date"
                       value={toDate} onChange={handleToDateChange}
                       data-cy={'toDate'} />
        </Col>
        <Col xs={4}>
          <Button onClick={handleExportToCSV} data-cy={'exportToCSV'}>Export to CSV</Button>
        </Col>
      </Row>
      <Row>
        {loading && <Col xs={12}>Loading...</Col>}
        <Col xs={12} className="error">{error}</Col>
      </Row>
      <Table striped responsive>
        <thead>
          <tr>
            <th>First name</th>
            <th>Last name</th>
            <th>Email</th>
            <th>Phone number</th>
            <th>Date Registered</th>
          </tr>
        </thead>
        <tbody>
        {billingReportRows.map(({
          firstName, lastName, candidateEmail, phoneNumber, dateRegistered
        }, index) =>
          <tr key={index} data-cy={'userData'}>
            <td className="text-break">{firstName}</td>
            <td className="text-break">{lastName}</td>
            <td className="text-break">{candidateEmail}</td>
            <td className="text-break">{phoneNumber}</td>
            <td><DateDisplay date={dateRegistered}/></td>
          </tr>
        )}
        </tbody>
      </Table>
      <Paginator page={page} pageSize={pageSize} total={total}
                 onChange={handlePageChange} />
    </section>
  );
};

export default BillingReportPage;
