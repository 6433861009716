import { v4 as uuid } from 'uuid';
import {
  POPULATE_CANDIDATE_DETAILS_SUCCESS,
  SHORTLIST_CANDIDATE_SUCCESS,
  FAVORITE_CANDIDATE_SUCCESS,
  RATE_CANDIDATE_VIDEO_SUCCESS, RATE_CANDIDATE_SUCCESS
} from './actions';
import { UNSHORTLIST_CANDIDATE_SUCCESS } from '../../actionTypes';


const defaultState = {
  rateUnit: '',
  rate: 0,
  rating: 0,
  availability: 0, // number of days person is available - 0 means person is available now
  interestsVideoUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4', // '' implies no video provided
  hobbiesVideoUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4', // '' implies no video provided
  industriesInterested: [],
  categoriesInterested: [],
  name: '',
  candidateId: uuid(),
  employerId: '1dbb011b-0bbd-4e06-929c-123cb809ee07', //hardcoded
  favorited: false,
  shortlisted: false
};



export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case RATE_CANDIDATE_SUCCESS: {
      return defaultState;
    }
    case RATE_CANDIDATE_VIDEO_SUCCESS: {
      return defaultState;
    }
    case FAVORITE_CANDIDATE_SUCCESS: {
      return { ...state, favorited: true };
    }
    case SHORTLIST_CANDIDATE_SUCCESS: {
      return { ...state, shortlisted: true };
    }
    case UNSHORTLIST_CANDIDATE_SUCCESS: {
      return { ...state, shortlisted: false };
    }
    case POPULATE_CANDIDATE_DETAILS_SUCCESS: {
      const candidate = action.payload.result.data[0];
      return { ...state,
        rateUnit: 'hr',
        rate: candidate.expectedHourlyRate,
        rating: candidate.ratingAverage,
        availability: '2 weeks hardcoded value atm', // number of days person is available - 0 means person is available now
        interestsVideoUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4', // '' implies no video provided
        hobbiesVideoUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4', // '' implies no video provided
        industriesInterested: [],
        categoriesInterested: [],
        location: candidate.city + " " + candidate.country,
        name: candidate.title + " " + candidate.firstName + " " + candidate.lastName,
        candidateId: candidate.id,
        favorited: ( typeof candidate.favorites !== 'undefined' && candidate.favorites instanceof Array) ? candidate.favorites.includes(state.employerId) : false,
        shortlisted: ( typeof candidate.shortlists !== 'undefined' && candidate.shortlists instanceof Array) ? candidate.shortlists.includes(state.employerId) : false,
      };
    }
    case POPULATE_CANDIDATE_DETAILS_SUCCESS: {
      return { ...state,
        rateUnit: 'hr',
        rate: 85,
        rating: 4.5,
        availability: 1, // number of days person is available - 0 means person is available now
        interestsVideoUrl: 'http://foo.com/asdf', // '' implies no video provided
        hobbiesVideoUrl: 'http://foo.com/asdf', // '' implies no video provided
        industriesInterested: [],
        categoriesInterested: [],
        name: 'Bob Marley',
        candidateId: uuid(),
        employerId: uuid()
      };
    }
    default:
      return { ...state, shortlisted: true };
  }
}
