import React from "react";
import { createUseStyles, useTheme } from 'react-jss';
import { Modal } from 'react-bootstrap';
import Button from '../../components/Button';
import HeaderText from '../../components/HeaderText';

const useStyles = createUseStyles(theme => {
  termsOfService: {}
});

const TermsOfService = ({ onHide, show }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return <Modal size="xl" onHide={onHide} show={show} className={classes.termsOfService}>
    <Modal.Header closeButton>
      <Modal.Title>IAAM Employer Terms Of Service</Modal.Title>
    </Modal.Header>
    <Modal.Body data-cy={'employerTermsOfService'}>
      <HeaderText level={3}>1. TERMS AND CONDITIONS – TEMPORARY AND CONTRACT EMPLOYMENT</HeaderText>
      <p>This section defines and confirms the search terms as outlined in our proposal and confirms our agreement with respect to the referral of candidates for employment by your esteemed Company, its parents, affiliates or subsidiaries.</p>

      <HeaderText level={4}>1.1 DEFINITIONS</HeaderText>
      <p>1.1.1 In these Terms of Business, the following definitions apply:</p>
      <p>“Assignment” means the period during which a Temporary Worker is supplied to render services to the Client;</p>
      <p>“Client” means the person, firm or corporate body together with any affiliates as that term is defined in the BC Business Corporations Act to whom the Temporary Worker is supplied or introduced;</p>
      <p>“The Employment Business” means IAAM Recruitment (“IAAM”) of Vancouver BC;</p>
      <p>“Engages/Engaged/Engagement” means the engagement, employment or use of the Temporary Worker directly by the Client or through any other employment business on a permanent or temporary basis, whether under a contract of service or for services, an agency, license, franchise or partnership arrangement; or any other engagement.</p>
      <p>“Temporary Worker” means an individual who is introduced by the Employment Business to render services to the Client.</p>
      <p>“Conversion Fee” means 20% of a Temporary Worker’s base salary calculated as if that salary was earned for the period of three months.</p>
      <p>1.1.2 Unless the context otherwise requires, references to the singular include the plural. The headings contained in these Terms are for convenience only and do not affect their interpretation.</p>

      <HeaderText level={4}>1.2 THE CONTRACT</HeaderText>
      <p>1.2.1 These Terms and Conditions constitute the contract between the Employment Business and the Client for the supply of Temporary and Contract Worker services by the Employment Business to the Client.</p>
      <p>1.2.2 These Terms and Conditions contain the entire agreement between the parties and unless otherwise agreed in writing by a senior manager of the Employment Business, these Terms and Conditions prevail over any terms of business or purchase conditions put forward by the Client.</p>
      <p>1.2.3 No variation or alteration to these Terms and Conditions shall be valid unless the details of such variation are agreed between the Employment Business and the Client and are set out in writing and a copy of the varied Terms and Conditions is given to the Client stating the date on or after which such varied Terms and Conditions shall apply.</p>

      <HeaderText level={4}>1.3 NOTIFICATION AND CHARGES</HeaderText>
      <p>The Client agrees to:</p>
      <p>1.3.1 The Client agrees to pay such hourly rates and for such hours on account of the Temporary/Contract Worker’s services with respect to an Assignment as shall be notified to and agreed with the Client. In addition, the Client will pay to the Employment Business the actual amount, without mark-up or commission, of any of the Temporary Worker’s expenses on account of travel or accommodation that have been approved in advance by the Client, plus applicable taxes.</p>
      <p>1.3.2 The charges are invoiced to the Client on a weekly bases and are payable within 7 days from Invoice date.</p>

      <HeaderText level={4}>1.4 TIME SHEETS</HeaderText>
      <p>1.4.1 At the end of each week of an Assignment (or at the end of the Assignment where it is for a period of one week or less) the Client shall sign the Employment Business’ time sheet verifying the number of hours worked by the Temporary Worker during that week.</p>
      <p>1.4.2 Signature of the time sheet by the Client is confirmation of the number of hours worked. If the Client is unable to sign a time sheet produced for authentication by the Temporary Worker because the Client disputes the hours claimed, the Client shall inform the Employment Business as soon as is reasonably practicable and shall co-operate fully and in a timely fashion with the Employment Business to enable the Employment Business to establish what hours, if any, were worked by the Temporary Worker. No amount shall be payable under this Agreement until any dispute has been resolved and the time sheet signed by the Client.</p>
      <p>1.4.3 The Client shall not be entitled to decline to sign a timesheet on the basis that he is dissatisfied with the work performed by the Temporary Worker. In cases of unsuitable work, the Client should apply the provisions of clause 1.8.1 below.</p>

      <HeaderText level={4}>1.5 INVOICING AND PAYMENT</HeaderText>
      <p>1.5.1 The Employment Business assumes responsibility for paying the Temporary Worker and where appropriate, for the deduction and payment of all Employment Insurance contributions, Canada Pension Plan contributions, applicable Income Tax, and other statutory deductions and remittances. In the event that the Employment Business fails to make any such deductions or to pay such deductions as required or if any person, governmental authority or agency characterizes or deems the Temporary Worker to be a worker or employee of the Client, which then obligates the Client to make payments or remittances with respect to or on behalf of the Temporary Worker (including but not limited to income taxes, Canada Pension Plan and employment insurance contributions ), the Employment Business hereby covenants and agrees to reimburse the Client on account of any such payments or remittances, including any penalties assessed thereon, and the Client shall be entitled to deduct such amounts from any monies due and owing to the Employment Business.</p>

      <HeaderText level={4}>1.6 CONVERSION FEES</HeaderText>
      <p>1.6.1 In the event of the Engagement by the Client of a Temporary Worker supplied by the Employment Business either (1) directly or (2) pursuant to a supply by another employment business, within either:</p>
      <p>• The duration of the Temporary Worker’s Assignment, or</p>
      <p>• 14 weeks from the start of the first Assignment performed by that Temporary Worker (the first Assignment being each new assignment where there has been a break of more than 84 days (12 weeks) since the end of the previous Assignment), the Client shall be liable to pay a Conversion Fee.</p>
      <p>1.6.2 The Client must give the Employment Business written notice within 5 days of an Engagement as described in section 1.6.1.</p>
      <p>1.6.3 Notwithstanding section 1.6.1., the Client may, provided it has given the notice under section 1.6.2, elect to convert its Engagement of the Temporary Worker to an Assignment under this Agreement.</p>

      <HeaderText level={4}>1.7 LIABILITY AND INSURANCE</HeaderText>
      <p>1.7.1 Whilst every effort is made by the Employment Business to give satisfaction to the Client by ensuring reasonable standards of skills, integrity and reliability from Temporary Workers and further to provide them in accordance with the Client’s booking details, the Employment Business is not liable for any loss, expense, damage or delay arising from any failure to provide any Temporary Worker for all or part of the period of booking. The Employment Business must maintain the insurance coverage set out in Schedule A. In the event that a Temporary Worker will be using a vehicle in the performance of the Assignment, the Temporary Worker must carry $2 million third party legal liability automobile insurance.</p>
      <p>1.7.2 Temporary Workers are deemed to be under the direction of the Client from the time they report to take up duties and for the duration of the Assignment.. Where the Employment Business is the employer of a Temporary Worker, it shall be responsible and/or liable for losses or damages arising out of their negligent act or omission of the Temporary Worker during their Assignment, and will be required to indemnify and save harmless the Client, its directors, officers, and employees from and against all claims, loss, damages, demands, complaints (including, without limitation, complaints pursuant to human rights legislation), actions (including, without limitation, wrongful dismissal actions), suits, liabilities and/or costs (including actual legal fees and disbursements), arising from or caused by any breach of contract, errors, omissions or negligent acts or omissions of the Temporary Worker, its directors, officers, employees, and agents in relation to the Assignment including breach of applicable laws by the Temporary Worker, unless such claim, loss, damage, demand, complaint, action, suit, liability and/or cost is the result of errors, omissions or negligent acts or omissions of the Client, its directors, officers, and employees. If the Temporary Worker is under contract with the Employment Business, then the Employment Business will ensure that such contract includes an indemnity in favour of the Client on the same terms and conditions as set out in this section.</p>
      <p>1.7.3 The Client shall advise the Employment Business of any special health and safety matters about which the Employment Business is required to inform the Temporary Worker and about any requirements imposed by law or by any professional body, which must be satisfied if the Temporary Worker is to fill the Assignment. Where the Client requires or may require the services of a Temporary Worker for more than 48 hours in any week, the Client must notify the Employment Business of this requirement before the commencement of that week.</p>

      <HeaderText level={4}>1.8 TERMINATION</HeaderText>
      <p>1.8.1 The Client undertakes to supervise the Temporary Worker sufficiently to ensure the Client’s satisfaction with the Temporary Worker’s standards of workmanship. If the Client reasonably considers that the services of the Temporary Worker are unsatisfactory, the Client may terminate the Assignment either by instructing the Temporary Worker to leave the Assignment immediately, or by directing the Employment Business to remove the Temporary Worker. The Employment Business may, in such circumstances, reduce or cancel the charges for the time worked by that Temporary Worker.</p>
      <p>1.8.2 In addition to the rights set out in section 1.8.1, any of the Client, the Employment Business or the Temporary Worker may terminate an Assignment at any time on two weeks’ written notice to the others and without liability, except for any charges that may be due and owing under section 1.3.1 and section 1.3.2.</p>
      <p>1.8.3 The Client shall notify the Employment Business immediately and without delay and in any event within 12 hours if the Temporary Worker fails to attend work or notifies the Client that the Temporary Worker is unable to attend work for any reason.</p>
      <p>1.8.4 The Employment Business shall notify the Client immediately if it receives or otherwise obtains information which gives it reasonable grounds to believe that a Temporary Worker supplied to the Client is unsuitable for the Assignment in which case, the Client may elect to immediately terminate the Assignment without liability, except for any changes that may be due and owing under section 1.3.1.</p>

      <HeaderText level={4}>1.9 LAW</HeaderText>
      <p>1.9.1 These Terms are governed by the law of BC and are subject to the exclusive jurisdiction of the Courts of BC and Canada.</p>

    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide} data-cy={'close'}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>;
};

export default TermsOfService;
