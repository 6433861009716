import * as yup from "yup";
import  jwt_decode from "jwt-decode";
import { addDays, startOfToday } from "date-fns";
import { isLive, isHospitalityNetwork } from '../../config';

export const isEmailConfirmed = state => (state.auth.jwt && state.auth.jwt.emailConfirmed) || false;
export const getEmailConfirmationError = state => state.candidateConfirmEmail.error;
export const getConfirmingEmail = state => state.candidateConfirmEmail.confirming;

export const getCandidateVideoUpload = (state) => state.candidateVideoUpload;

export const getCandidateVideos = state => state.candidate.videos;

const introQuestionId = 'c773617e-b387-48e2-af6f-4f7b4fe84d21';
export const getCandidateIntroVideo = state => getCandidateVideos(state).find(
  video => video.questionId === introQuestionId,
) || null;

const statusText = {
  queued: 'Is queued for processing.',
  pendingApproval: 'Is pending approval.',
  ready: 'Your video has been approved and sent to employers.',
  failed: 'Is in a failed state. Please contact support.',
  rejected: 'Has been rejected.',
  notSubscribed: 'Ready to be viewed by employers once you have subscribed.',
};
export const getCandidateVideoStatusText = video => state =>
  (video && statusText[video.status]) || '';

export const getCandidateValidationSchema = state => yup.object({
    firstName: yup.string().required('First name is required').max(20, yup => `Value cannot be more than ${yup.max} characters`),
    lastName: yup.string().required('Last name is required').max(20, yup => `Value cannot be more than ${yup.max} characters`),
    emailAddress: yup.string().email('Invalid email').required('Email address is required').max(255, yup => `Value cannot be more than ${yup.max} characters`),
    acceptedTOS: yup.bool().oneOf([true], 'Must Accept Terms and Conditions'),
    password: yup.string().required('Password is required').min(8, 'Too Short!'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], "Passwords must match").required('Password confirm is required'),
    jobCategories: yup.array().required('Job categories is required'),
});

export const getCandidateDetailsValidationSchema = ({ minimumHourlyRate }) => state => yup.object({
    firstName: yup.string().required('First name is required').max(20, yup => `Value cannot be more than ${yup.max} characters`),
    middleName: yup.string().max(20, yup => `Value cannot be more than ${yup.max} characters`),
    lastName: yup.string().required('Last name is required').max(20, yup => `Value cannot be more than ${yup.max} characters`),
    city: yup.string().required('City is required').max(40, yup => `Value cannot be more than ${yup.max} characters`),
    province: yup.string().required('Province is required').max(40, yup => `Value cannot be more than ${yup.max} characters`),
    country: yup.string().required('Country is required').max(40, yup => `Value cannot be more than ${yup.max} characters`),
    expectedHourlyRate: yup.number().required('Expected hourly rate is required').min(minimumHourlyRate, `Must be at least $${minimumHourlyRate} per hour!`),
    expectedStartDate: yup.date().min(addDays(startOfToday(), 1), 'Expected start date must be in the future'),
    postalCode: yup
         .string()
         .when('country', {
             is: 'United States',
             then: yup.string().matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Invalid postal code'),
         })
         .when('country', {
             is: 'Canada',
             then: yup.string().matches(/^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/, 'Invalid postal code'),
         })
         .required('Postal code is required'),
    phoneNumber: yup
         .string()
         .when('country', {
             is: country => ["United States", "Canada"].includes(country),
             then: yup.string().matches(/^[2-9]\d{2}[2-9]\d{2}\d{4}$/, 'Invalid phone number')
         })
         .required('Phone number is required'),
    jobCategories: yup.array().required("Job categories is required"),
    legalToWorkInCountry: yup.string().nullable()
      .required('Legal to work in country is required')
      .matches(/^(yes)$/, 'You cannot proceed unless you are legally allowed to work in Canada'),
    openToRelocate: yup.string().nullable()
      .required('Open to relocate is required')
      .matches(/^(yes|no)$/),
});

export const getQuestions = state => state.candidate.questions;
export const getSelectedQuestionId = state => state.candidate.selectedQuestionId;
export const isSelectedQuestion = ({ questionId }) => state => questionId === getSelectedQuestionId(state);
export const getSelectedQuestion = state => getQuestions(state).find(isSelectedQuestion);

export const getCandidateId = state => (state.auth.jwt && state.auth.jwt.sub) || '';
export const getCandidateOffers = state => state.candidate.offers;
export const getCandidateOffer = state => state.candidate.offer;
export const getShowAcceptedOfferConfirm = state => state.candidate.showAcceptedOfferConfirm;

const offerStatusText = Object.freeze({
  new: 'New',
  accepted: 'Accepted',
});
export const getCandidateOfferStatusText = offer => state =>
  (offer && offerStatusText[offer.offerStatus]) || 'N/A';

export const getCandidateProfile = state => state.candidatePersonalDetails;

export const getCandidateEmployerShortlist = state => state.candidate.shortlist;

export const getRegistrationErrors = state => state.candidate.registrationErrors;

export const getPromoInfo = state => state.promoInfo;
