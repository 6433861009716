import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminCandidates } from './selectors';
import { Container, FormControl, Table } from 'react-bootstrap';
import { fetchCandidates, findCandidateByVideoId } from './actions';
import Paginator from '../../components/Paginator';

const CandidatesPage = () => {
  const dispatch = useDispatch();
  const { results: candidates, total } = useSelector(getAdminCandidates);
  const [{ page, pageSize, videoId }, setState] = useState({
    page: 1,
    pageSize: 25,
    videoId: '',
  });
  useEffect(() => {
    dispatch(fetchCandidates(page, pageSize));
  }, [dispatch, page, pageSize]);
  let searchTimeout;
  const handleVideoIdChange = (evt) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
      searchTimeout = null;
    }
    const videoId = evt.target.value;
    setState(state => ({ ...state, videoId }));
    searchTimeout = setTimeout(() => videoId
      ? dispatch(findCandidateByVideoId(videoId))
      : dispatch(fetchCandidates(page, pageSize)), 1000);
  };
  const handlePageChange = page => setState(state => ({ ...state, page }));
  return (
    <section>
      <FormControl type="text" placeholder="Search by video ID"
                   value={videoId} onChange={handleVideoIdChange} />
      <Table striped responsive>
        <thead data-cy={'adminCandidatePage'}>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone #</th>
            <th>Subscribed</th>
          </tr>
        </thead>
        <tbody>
        {candidates.map(({
          candidateId, firstName, lastName, emailAddress, phoneNumber,
          subscribed,
        }, index) =>
          <tr key={index} data-cy={'candidate'}>
            <td>{candidateId.split('-')[0]}</td>
            <td className="text-break">{firstName}</td>
            <td className="text-break">{lastName}</td>
            <td className="text-break">{emailAddress}</td>
            <td>{phoneNumber || 'N/A'}</td>
            <td>{subscribed ? 'YES': 'NO'}</td>
          </tr>
        )}
        </tbody>
      </Table>
      <Paginator page={page} pageSize={pageSize} total={total}
                 onChange={handlePageChange} />
    </section>
  );
};

export default CandidatesPage;
