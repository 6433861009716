import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Dropdown, ButtonGroup, Spinner } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { isMobile, isTablet } from 'react-device-detect';
import CandidateVideoCard, { formatRate } from './CandidateVideoCard';
import Button from '../../components/Button';
import capitalize from '../../utils/strings';
import { fetchOffers } from './actions';
import { getOffers } from './selectors';
import TabHeader from '../../components/TabHeader';

const StatusEnumToText = Object.freeze({
  '': 'All',
  new: 'New Offer',
  accepted: 'Offer Accepted',
  rejected: 'Offer Rejected',
});
const Statuses = Object.entries(StatusEnumToText);

const useStyles = createUseStyles(({
  filterDropdown: {
    width: '100%',
    padding: '1rem 0',
    '&>div': {
      width: '100%',
    },
    '&>div:first-child': {
      fontWeight: 'bold',
      fontSize: '1rem',
      borderTopLeftRadius: '0.25rem',
      borderBottomLeftRadius: '0.25rem',
      backgroundColor: 'white',
      padding: '0.5rem 1.5rem',
      border: 'solid 1px #0070C0',
    },
    '&>.dropdown-toggle-split': {
      borderLeft: 0,
      backgroundColor: ['#0070C0', '!important'],
      '&:hover': {
        backgroundColor: ['#121A45', '!important'],
      },
      '&:focus': {
        boxShadow: 'none !important',
      }
    }
  },
  nextBtn: {
    textAlign: 'right',
  },
  pageText: {
    textAlign: 'center',
    padding: '0.375rem 0'
  },
  alignCenter: {
    textAlign: 'center'
  },
  paginationRow: {
    padding: '1rem 0'
  }
}));

//TODO we might need to adjust these numbers once video is shown
const pageSize = isMobile ? (isTablet ? 6 : 4) : 8;

const OffersPage = () => {
  const { addToast } = useToasts();
  const loadMoreRef = useRef();
  const [filter, setFilter] = useState('');
  const [start, setStart] = useState(0);
  const [count, setCount] = useState(pageSize);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error, data, total } = useSelector(getOffers);
  const page = Math.ceil(start / pageSize) + 1;
  const lastPage = Math.max(Math.ceil(total / pageSize), 1);

  useEffect(function() {
    function loadMore() {
      if (loading) return;
      //TODO loading more should append result instead of loading from start
      setCount(count => count + pageSize);
    }
    function handleScroll(evt) {
      if (!loadMoreRef.current) return;
      const scrollBottom = window.scrollY + window.innerHeight;
      const refOffsetBottom = loadMoreRef.current.offsetTop + loadMoreRef.current.offsetHeight;
      if (scrollBottom <= refOffsetBottom) return;
      loadMore();
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreRef, loading]);

  useEffect(function() {
    error && addToast(error, { appearance: 'error' });
  }, [error]);

  useEffect(loadData, [start, count, filter]);
  function loadData() {
    dispatch(fetchOffers({ start, count, status: filter }));
  }

  function viewOffer(offerId) {
    history.push('/offer?id=' + offerId);
  }

  function prevPage() {
    setStart(start => start - pageSize);
  }
  function nextPage() {
    setStart(start => start + pageSize);
  }

  return (
    <section>
      <TabHeader>Your Offers</TabHeader>
      <p>
        If you need further assistance, please reach out to our Customer Service
        Team at <a href="mailto:info@iaam.ca">info@iaam.ca</a>
      </p>
      <Row>
        <Col xs={12} sm={6} lg={4} xl={3}>
          <Dropdown as={ButtonGroup} alignRight className={classes.filterDropdown}>
            <div>{StatusEnumToText[filter]}</div>
            <Dropdown.Toggle split data-cy={'offerStatusDropdown'}/>
            <Dropdown.Menu className="mr-auto">
              {Statuses.map(([key, value]) => (
                <Dropdown.Item as="button" key={key} eventKey={key} onClick={() => setFilter(key)} data-cy={'offerStatus'}>{value}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        {data.map(
          ({ offerId, ...rest }) => (
            <Col
              key={offerId}
              xs={12} sm={6} lg={4} xl={3}
            >
              <CandidateVideoCard
                data={rest}
                additionalFields={[
                  { label: 'Offer Rate', key: 'offeredRate', render: x => formatRate(x) },
                  { label: 'Job Title', key: 'jobTitle' },
                  { label: 'Start Date', key: 'startDate' },
                  { label: 'Status', key: 'status', render: x => capitalize(x) }
                ]}
                actionsComponent={<Button width="100%" onClick={() => viewOffer(offerId)} data-cy={'viewOffer'}>View Offer</Button>}
              />
            </Col>
          )
        )}
      </Row>
      {!loading && (
        isMobile
        ? <Row className={classes.paginationRow}>
            {(count < total) && <Col xs={12} ref={loadMoreRef} className={classes.alignCenter}>...</Col>}
        </Row>
        : <Row className={classes.paginationRow}>
          <Col xs={3}><Button disabled={page === 1} onClick={prevPage}>&lt;</Button></Col>
          <Col xs={6} className={classes.pageText}>{page}&nbsp;/&nbsp;{lastPage}</Col>
          <Col xs={3} className={classes.nextBtn}><Button disabled={page === lastPage} onClick={nextPage}>&gt;</Button></Col>
        </Row>
      )}
      {loading &&
      <Row>
        <Col xs={12} className={classes.alignCenter}><Spinner animation="border"/></Col>
      </Row>
      }
    </section>
  );
};

export default OffersPage;
