import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Form, ProgressBar } from 'react-bootstrap';
import { Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import { isBrowser } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { fetchCandidate, modifyCandidateDetails } from './actions';
import {
  getCandidateDetailsValidationSchema,
  getCandidateProfile,
} from './selectors';
import IAAMForm from '../../components/IaamForm';
import FormGroup from '../../components/FormGroup';
import FormLabel from '../../components/FormLabel';
import FormControl from '../../components/FormControl';
import Button from '../../components/Button';
import Autocomplete from 'react-autocomplete';
import { jobCategories, citiesByProvince, countries, statesOrProvincesPerCountry } from '../../constants';
import { getUserId } from '../../auth/selectors';
import TabHeader from '../../components/TabHeader';
import { createUseStyles } from 'react-jss';

const availableJobCategoriesOptions = jobCategories.map((x) => ({
  text: x,
  value: x,
}));

const availableCountriesOptions = countries.map((x) => ({
  text: x,
  value: x,
}));

const useStyles = createUseStyles(theme => ({
  control: {
    border: 'solid 1px #121A45',
    borderRadius: '0'
  }
}));

const minimumHourlyRate = parseFloat(process.env.REACT_APP_CANDIDATE_MIN_RATE);

/* this form is abridged of PII until we can figure out storage security
 * http://www.bclaws.ca/EPLibraries/bclaws_new/document/ID/freeside/00_03063_01#section34
 */
const EmployerProfilePage = () => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const candidateId = useSelector(getUserId);
  const profile = useSelector(getCandidateProfile);
  const [submitted, setSubmitted] = useState(false);
  const [resumeFile, setResumeFile] = useState();
  useEffect(() => {
    dispatch(fetchCandidate(candidateId));
  }, [dispatch, candidateId, profile.saved]);
  useEffect(() => {
    if (!submitted) return;
    if (profile.saved) {
      addToast('Profile saved.', { appearance: 'success' });
    } else if (profile.error) {
      addToast(profile.error, { appearance: 'error' });
    }
    if (profile.firstUpdate) {
      history.push('/dashboard/video-resume');
    }
  }, [addToast, submitted, profile.saved, profile.error]);

  const handleFileChange = (evt) => {
    const file = evt.target.files[0] || null;
    evt.persist();
    setResumeFile(file);
  };

  if (!submitted && (!profile.loaded || profile.fetching)) {
    return <div>Loading...</div>;
  }

  const wrapHandleSubmit = handleSubmit => evt => {
    if (resumeFile === undefined) setResumeFile(null);
    return handleSubmit(evt);
  };

  const shouldItemRender = (item, value) => item.startsWith(value);

  return (
    <section>
      <TabHeader data-cy={'headerText'}>Profile Information</TabHeader>
      <Formik
        validationSchema={getCandidateDetailsValidationSchema({ minimumHourlyRate })}
        onSubmit={(values) => {
          if (!resumeFile) return;
          dispatch(
            modifyCandidateDetails({ candidateId, resumeFile, ...values }),
          );
          setSubmitted(true);
        }}
        initialValues={{ ...profile }}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue
        }) => (
          <IAAMForm noVerticalMargin onSubmit={wrapHandleSubmit(handleSubmit)} data-cy={'form'}>
            <Form.Group controlId="formFirstName">
              <FormLabel required>First Name</FormLabel>
              <FormControl
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.firstName && errors.firstName}
                autoFocus={isBrowser}
                data-cy={'firstName'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'firstNameError'}>
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>

            <FormGroup controlId="formMiddleName">
              <FormLabel>Middle Name</FormLabel>
              <FormControl
                type="text"
                name="middleName"
                value={values.middleName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.middleName && errors.middleName}
                data-cy={'middleName'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'middleNameError'}>
                {errors.middleName}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formLastName">
              <FormLabel required>Last Name</FormLabel>
              <FormControl
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.lastName && errors.lastName}
                data-cy={'lastName'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'lastNameError'}>
                {errors.lastName}
              </Form.Control.Feedback>
            </FormGroup>

            {/*
            <FormGroup controlId="formEmail">
              <BoldText>E-mail Address</BoldText>
              <FormControl type="email" name="emailAddress" value={values.emailAddress} onChange={handleChange}
                            isInvalid={touched.emailAddress && errors.emailAddress}
              />
                <Form.Control.Feedback type="invalid">
                    {errors.emailAddress}
                </Form.Control.Feedback>
            </FormGroup>
            */}
            {/*
            <FormGroup controlId="formBirthDate">
              <BoldText>Birth Date</BoldText>
              <FormControl type="date" name="birthDate" value={values.birthDate} onChange={handleChange}
                            isInvalid={touched.birthDate && errors.birthDate}
              />
                <Form.Control.Feedback type="invalid">
                    {errors.birthDate}
                </Form.Control.Feedback>
            </FormGroup>
            */}

            <FormGroup controlId="formStreetAddress1">
              <FormLabel>Street Address</FormLabel>
              <FormControl
                type="text"
                name="address1"
                value={values.address1}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>

            <FormGroup controlId="formStreetAddress2">
              <FormLabel>Street Address Line 2</FormLabel>
              <FormControl
                type="text"
                name="address2"
                value={values.address2}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>

            <FormGroup controlId="formCountry">
              <FormLabel required>Country</FormLabel>
              <FormControl
                as="select"
                name="country"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.country && errors.country}
                data-cy={'countryDropdown'}
              >
                <option value="">-- Choose a country --</option>
                {availableCountriesOptions.map((option) => (
                  <option key={option.text} value={option.value} data-cy={'countryOption'}>
                    {option.text}
                  </option>
                ))}
              </FormControl>
              <Form.Control.Feedback type="invalid" data-cy={'countryError'}>
                {errors.country}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formProvince">
              <FormLabel required>State / Province</FormLabel>
              <FormControl
                as="select"
                name="province"
                value={values.province}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.province && errors.province}
                data-cy={'provinceDropdown'}
              >
                <option value="">-- Choose a state/province --</option>
                {(statesOrProvincesPerCountry[values.country] || []).map((value) => (
                  <option key={value} value={value} data-cy={'provinceOption'}>
                    {value}
                  </option>
                ))}
              </FormControl>
              <Form.Control.Feedback type="invalid" data-cy={'provinceError'}>
                {errors.province}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formCity">
              <FormLabel required>City</FormLabel>
              <Autocomplete
                getItemValue={(item) => item}
                items={citiesByProvince[values.province] || []}
                renderItem={(item, isHighlighted) =>
                  <div key={item} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                    {item}
                  </div>
                }
                shouldItemRender={shouldItemRender}
                value={values.city}
                onChange={(e) => handleChange(e)}
                onSelect={(val) => setFieldValue('city', val, true)}
                inputProps={{
                  name: 'city',
                  className: `${classes.control} form-control ${touched.city && errors.city ? 'is-invalid': ''}`,
                  style: {
                    width: '100%',
                    borderRadius: '0'
                  }
                }}
                wrapperStyle={{
                  width: '100%'
                }}
                renderInput={(props) => <>
                  <input {...props} autoComplete="new-password" data-cy={'city'}/>
                  <Form.Control.Feedback type="invalid" data-cy={'cityError'}>
                    {errors.city}
                  </Form.Control.Feedback>
                  </>
                }
              />
            </FormGroup>

            <FormGroup controlId="formPostalCode">
              <FormLabel required>Zip / Postal Code</FormLabel>
              <FormControl
                type="text"
                name="postalCode"
                value={values.postalCode}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.postalCode && errors.postalCode}
                data-cy={'postalCode'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'postalCodeError'}>
                {errors.postalCode}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formIndustries">
              <FormLabel required>Job Categories</FormLabel>
              <FormControl
                as="select"
                multiple
                value={values.jobCategories}
                name="jobCategories"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.jobCategories && errors.jobCategories}
                data-cy={'jobCategoriesSelect'}
              >
                {availableJobCategoriesOptions.map((option) => (
                  <option key={option.text} value={option.value} data-cy={'jobCategoriesOption'}>
                    {option.text}
                  </option>
                ))}
              </FormControl>
              <Form.Control.Feedback type="invalid" data-cy={'jobCategoriesError'}>
                {errors.jobCategories}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formPhoneNumber">
              <FormLabel required>Phone Number</FormLabel>
              <FormControl
                type="tel"
                name="phoneNumber"
                value={values.phoneNumber}
                placeholder="e.g. 6048123456"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.phoneNumber && errors.phoneNumber}
                data-cy={'phoneNumber'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'phoneNumberError'}>
                {errors.phoneNumber}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formLegal">
              <Form.Check
                type="checkbox"
                id="legalYes"
                name="legalToWorkInCountry"
                label={`I am legally allowed to work in ${values.country}`}
                checked={values.legalToWorkInCountry === 'yes'}
                onChange={evt => setFieldValue(
                  'legalToWorkInCountry',
                  evt.target.checked ? 'yes' : 'no',
                )}
                onBlur={handleBlur}
                isInvalid={
                  touched.legalToWorkInCountry && errors.legalToWorkInCountry
                }
                feedback={errors.legalToWorkInCountry}
                data-cy={'legalYes'}
              />
            </FormGroup>

            <FormGroup controlId="formRelocate">
              <FormLabel required bold>
                Are you willing to relocate?
              </FormLabel>
              <br/>
              <Form.Check
                type="radio"
                id="relocateYes"
                name="openToRelocate"
                value="yes"
                label="Yes"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.openToRelocate === 'yes'}
                isInvalid={touched.openToRelocate && errors.openToRelocate}
                data-cy={'relocateYes'}
              />
              <Form.Check
                type="radio"
                id="relocateNo"
                name="openToRelocate"
                value="no"
                label="No"
                checked={values.openToRelocate === 'no'}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.openToRelocate && errors.openToRelocate}
                feedback={errors.openToRelocate}
                data-cy={'relocateNo'}
              />
            </FormGroup>

            <FormGroup controlId="formExpectedStartDate">
              <FormLabel>Expected Start Date (leave empty for immediate availability)</FormLabel>
              <FormControl
                type="date"
                name="expectedStartDate"
                value={values.expectedStartDate}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.expectedStartDate && errors.expectedStartDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.expectedStartDate}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formExpectedHourlyRate">
              <FormLabel required>Expected Hourly Rate</FormLabel>
              <FormControl
                type="number"
                name="expectedHourlyRate"
                value={values.expectedHourlyRate}
                placeholder="e.g. 23"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  touched.expectedHourlyRate && errors.expectedHourlyRate
                }
                data-cy={'expectedRate'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'expectedRateError'}>
                {errors.expectedHourlyRate}
              </Form.Control.Feedback>
            </FormGroup>
            {/*
            <FormGroup  className={classes.group} controlId="formSIN">
              <BoldText>SIN</BoldText>
              <FormControl type="number" name="SIN" value={values.SIN} placeholder="e.g. 732564875" onChange={handleChange}
                            isInvalid={touched.SIN && errors.SIN}
              />
                <Form.Control.Feedback type="invalid">
                    {errors.SIN}
                </Form.Control.Feedback>
            </FormGroup>
            */}
            {/*
            <FormGroup  className={classes.group} controlId="formTaxCreditForms">
              <BoldText>Upload BC Tax Credit Forms</BoldText>
              <FormControl type="file" name="taxForm" onChange={handleFileChange}/>
            </FormGroup>
            */}

            <FormGroup controlId="formComments">
              <FormLabel>Summary of Professional Experience (max 350 words)</FormLabel>
              <FormControl
                type="text"
                name="comments"
                value={values.comments}
                as="textarea"
                rows="3"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.comments && errors.comments}
                data-cy={'comments'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'commentsError'}>
                {errors.comments}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formResumeFile">
              <FormLabel required>Resume <sub>(PDF only)</sub></FormLabel>
              {profile.resumeUrl &&
                <div>
                  <a href={profile.resumeUrl} target="_blank" rel="noopener noreferrer" data-cy={'viewResume'}>View Resume</a>
                </div>
              }
              <FormControl type="file" name="resumeFile" accept="application/pdf"
                           onChange={handleFileChange}
                           isInvalid={resumeFile !== undefined && !resumeFile}
                           data-cy={'resumeUpload'}/>
              <ProgressBar hidden={!profile.saving} min={0} max={100}
                           now={profile.uploadProgress} variant="info" />
              <Form.Control.Feedback type="invalid" data-cy={'resumeFileError'}>
                You must upload a resume
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup>
              <Button width="50%" type="submit" disabled={profile.saving} data-cy={'submit'}>
                Submit
              </Button>
            </FormGroup>

            <FormikErrorFocus
              // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
              offset={-10}
              align={'top'}
              focusDelay={200}
              ease={'linear'}
              duration={500}
            />

          </IAAMForm>)
        }
      </Formik>
    </section>
  );
};

export default EmployerProfilePage;
