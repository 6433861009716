import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import './fonts/Barlow-Black.ttf';
import './fonts/Barlow-Bold.ttf';
import './fonts/Barlow-Regular.ttf';
import './fonts/Barlow-Italic.ttf';
import './fonts/Barlow-BlackItalic.ttf';
import './fonts/Barlow-BoldItalic.ttf';
import { version as currentVersion } from './version';
import { NEW_APP_CONTENT_AVAILABLE } from './actionTypes';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// ND - DO NOT register, it causes more issues than it solves, will enable later if needed
serviceWorker.unregister();

// cache buster
window.addEventListener('load', e => {
  fetch(process.env.PUBLIC_URL + '/meta.json?t=' + Date.now()).
    then(response => response.json()).
    then(meta => {
      const latestVersion = meta.version;
      if (latestVersion !== currentVersion) {
        store.dispatch({ type: NEW_APP_CONTENT_AVAILABLE });
      }
    });
});

if (window.Cypress) {
  window.store = store;
}