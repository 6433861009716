import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import IAAMForm from '../../components/IaamForm';
import FormGroup from '../../components/FormGroup';
import FormLabel from '../../components/FormLabel';
import FormControl from '../../components/FormControl';
import Button from '../../components/Button';
import HeaderText from '../../components/HeaderText';
import { registerCandidate } from './actions';
import { Formik } from 'formik';
import {
  getCandidateValidationSchema,
  getRegistrationErrors,
} from './selectors';
import { jobCategories } from '../../constants';
import { createUseStyles, useTheme } from 'react-jss';
import { isLoggedIn } from '../../auth/selectors';
import TermsOfService from './TermsOfService';

const availableJobCategoriesOptions = jobCategories.map(x => ({
  text: x, value: x,
}));

const useStyles = createUseStyles(theme => ({
  error: {
    color: 'red',
  },
}));

const RegisterCandidate = ({ history }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const [showTOS, setShowTOS] = useState(false);
  const registrationErrors = useSelector(getRegistrationErrors);
  const loggedIn = useSelector(isLoggedIn);

  if (loggedIn) {
    return (<div>
      You are already logged in. If you want to register a new candidate, please
      logout first.
    </div>);
  }

  function onShowTOS () {
    setShowTOS(true);
  }

  function closeTOS () {
    setShowTOS(false);
  }

  return (
    <>
      <TermsOfService show={showTOS} onHide={closeTOS}/>
      <Formik
        validationSchema={getCandidateValidationSchema}
        onSubmit={values => {
          dispatch(registerCandidate(values, { history }));
        }}
        initialValues={{
          firstName: '',
          lastName: '',
          emailAddress: '',
          password: '',
          confirmPassword: '',
          acceptedTOS: false,
          jobCategories: [],
        }}>
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          isInvalid,
          errors,
        }) => (
          <IAAMForm onSubmit={handleSubmit}>

            <HeaderText level={4}>Candidate Registration</HeaderText>

            <div className={classes.error}>{registrationErrors._}</div>

            <FormGroup controlId="formFirstName">
              <FormLabel required>First name</FormLabel>
              <FormControl type="text" name="firstName" value={values.firstName}
                           onChange={handleChange}
                           isInvalid={touched.firstName &&
                           (errors.firstName || registrationErrors.firstName)}
                           data-cy={'firstName'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'firstNameError'}>
                {errors.firstName || registrationErrors.firstName}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formLastName">
              <FormLabel required>Last name</FormLabel>
              <FormControl type="text" name="lastName" value={values.lastName}
                           onChange={handleChange}
                           isInvalid={touched.lastName &&
                           (errors.lastName || registrationErrors.lastName)}
                           data-cy={'lastName'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'lastNameError'}>
                {errors.lastName || registrationErrors.lastName}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formEmail">
              <FormLabel required>Email address</FormLabel>
              <FormControl type="email" name="emailAddress"
                           value={values.emailAddress} onChange={handleChange}
                           isInvalid={touched.emailAddress &&
                           (errors.emailAddress ||
                             registrationErrors.emailAddress)}
                           data-cy={'email'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'emailError'}>
                {errors.emailAddress || registrationErrors.emailAddress}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formPassword">
              <FormLabel required>Password</FormLabel>
              <FormControl type="password" name="password"
                           value={values.password} onChange={handleChange}
                           isInvalid={touched.password &&
                           (errors.password || registrationErrors.password)}
                           data-cy={'password'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'passwordError'}>
                {errors.password || registrationErrors.password}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formConfirmPassword">
              <FormLabel required>Confirm Password</FormLabel>
              <FormControl type="password" name="confirmPassword"
                           value={values.confirmPassword}
                           onChange={handleChange}
                           isInvalid={touched.confirmPassword &&
                           errors.confirmPassword}
                           data-cy={'confirmPassword'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'confirmPasswordError'}>
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formIndustries">
              <FormLabel required>Job Categories</FormLabel>
              <FormControl as="select" multiple value={values.jobCategories}
                           name="jobCategories"
                           onChange={handleChange}
                           isInvalid={touched.jobCategories &&
                           (errors.jobCategories ||
                             registrationErrors.jobCategories)}
                           data-cy={'jobCategoriesSelect'}>
                {availableJobCategoriesOptions.map(option => (
                  <option key={option.text} value={option.value} data-cy={'jobCategoriesOption'}>
                    {option.text}
                  </option>
                ))}
              </FormControl>
              <Form.Control.Feedback type="invalid" data-cy={'jobCategoriesError'}>
                {errors.jobCategories || registrationErrors.jobCategories}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formAcceptTOS">
              <Form.Check type="checkbox"
                          label={<FormLabel required>I accept the <a
                            href="#" onClick={onShowTOS} data-cy={'showTos'}>Terms of
                            Service</a></FormLabel>}
                          name="acceptedTOS"
                          value={values.acceptedTOS} onChange={handleChange}
                          isInvalid={touched.acceptedTOS &&
                          (errors.acceptedTOS ||
                            registrationErrors.acceptedTOS)}
                          data-cy={'acceptTos'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'acceptTosError'}>
                {errors.acceptedTOS || registrationErrors.acceptedTOS}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup>
              <Button width="50%" type="submit" data-cy={'submit'}>
                Register
              </Button>
            </FormGroup>

          </IAAMForm>
        )}
      </Formik>
    </>
  );
};

export default RegisterCandidate;
