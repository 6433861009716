import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { Form, Button, ProgressBar, Container } from 'react-bootstrap';
import {
    getAvailability,
    getMainVideo,
    getName,
    getRate,
    getRateUnit,
    getRating,
    getEmployerId,
    getCandidateId,
    getLocation, getIsFavorited, getIsShortlisted
} from './selectors';
import { shortlistCandidate, rateCandidate, rateCandidateVideo, favouriteCandidate, populateDetails } from './actions';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getUserId } from '../../auth/selectors';
import VideoDisplay from '../../components/VideoDisplay';

const useStyles = createUseStyles({
  container: {
    textAlign: 'center',
    paddingLeft: '15%',
    paddingRight: '15%',
    marginTop: '33%',
    height: '33%',
  },
  button: {
    minWidth: '33%',
    marginTop: '2em',
    color: ({ theme }) => theme.colorBackground,
    backgroundColor: ({ theme }) => theme.colorPrimary,
    borderColor: ({ theme }) => theme.colorPrimary,
  }
});



const CandidateDetailPage = ({ location, history }) => {
    const theme = useTheme();
    const employerId = useSelector(getUserId);
    const query = new URLSearchParams(location.search);
    const candidateId = query.get('id');
    const mainVideoId = useSelector(getMainVideo);
    const name = useSelector(getName);
    const candidateLocation = useSelector(getLocation);
    const rating = useSelector(getRating);
    const rate = useSelector(getRate);
    const rateUnit = useSelector(getRateUnit);
    const availability = useSelector(getAvailability);
    const isFavorited = useSelector(getIsFavorited);
    const isShortlisted = useSelector(getIsShortlisted);
    const dispatch = useDispatch();
    const classes = useStyles({ theme });

    useEffect(() => {
        dispatch(populateDetails({ candidateId }));
    }, [candidateId]);

    function handleFavoriteClick() {
        dispatch(favouriteCandidate({ employerId, candidateId }));
    }
    function handleShortlistClick() {
        dispatch(shortlistCandidate({ employerId, candidateId }));
    }
    function handleSetCandidateRating(rating) {
        dispatch(rateCandidate({ employerId, candidateId, rating: rating }));
    }
    function handleSetVideoRating(rating) {
        dispatch(rateCandidateVideo({ employerId, candidateId, videoId: mainVideoId, rating: rating }));
    }

  return (
    <Container fluid>
        <Row>
            <Col>
                <div hidden={isFavorited} className={classes.row}>
                    <Button className={classes.button} onClick={handleFavoriteClick}>Favorite this Candidate</Button>
                </div>
                <div hidden={!isFavorited}>
                    Favorited!
                </div>
            </Col>
            <Col>
                <Row>
                    {/* TODO fix me */}
                    <VideoDisplay video={{}} />
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <div className={classes.row}>
                                <label>Name: </label>
                                {name}
                            </div>
                        </Row>
                        <Row>
                            <div className={classes.row}>
                                <label>Location: </label>
                                {candidateLocation}
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col>

                <div className={classes.row}>
                    <label>Rating: </label>

                    {rating}
                </div>
                <div className={classes.row}>
                    <label>Rate: </label>

                    {rate *1.5}/{rateUnit}
                </div>
                <div className={classes.row}>
                    <label>Availability: </label>

                    {availability}
                </div>

                <div className={classes.row}>
                    <div hidden={isShortlisted}>
                        <Button className={classes.button} onClick={handleShortlistClick}>Shortlist this Candidate</Button>
                    </div>
                    <div hidden={!isShortlisted}>
                        Shortlisted!
                    </div>
                </div>


                {/*
      <div className={classes.row}>
          <label>Rate this Video: </label>
          <Rating onClick={handleSetVideoRating}>Rate this Video</Rating>
      </div>
      <div className={classes.row}>
          <label>Rate this Candidate: </label>
          <Rating onClick={handleSetCandidateRating}>Rate this Candidate</Rating>
      </div>*/}
            </Col>
        </Row>
    </Container>
  );
};

export default CandidateDetailPage;
