import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Form } from 'react-bootstrap';
import { makeOffer } from './actions';
import { Formik } from 'formik';
import {
  getMakeOfferErrors,
  getMakeOfferSchema,
  getMakingOffer,
} from './selectors';
import { populateDetails } from '../candidateDetails/actions';
import { getUserId } from '../../auth/selectors';
import FormLabel from '../../components/FormLabel';
import FormGroup from '../../components/FormGroup';
import FormControl from '../../components/FormControl';
import IAAMForm from '../../components/IaamForm';
import HeaderText from '../../components/HeaderText';
import Button from '../../components/Button';
import arrowLineLeft from '../../icons/arrow-left-line.svg';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  over: {
    color: 'red',
  },
  left: {},
});

const CharactersLeft = ({ maxLength, as = 'div', value = '', prefix = '(', suffix = ')' }) => {
  const classes = useStyles();
  const Container = as;
  const left = maxLength - value.length;
  return (
    <Container>
      {prefix}
      <span className={left < 0 ? classes.over : classes.left}>{left}</span>
      &nbsp;character{left > 1 ? 's' : ''} left
      {suffix}
    </Container>
  );
};

const MakeOffer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const candidateId = query.get("id");
  const employerId = useSelector(getUserId);
  const makingOffer = useSelector(getMakingOffer);
  const offerErrors = useSelector(getMakeOfferErrors);
  const hasError = Object.keys(offerErrors).length > 0;
  const [offerMade, setOfferMade] = useState(false);
  function handleGoBack() {
    history.goBack();
  }
  useEffect(() => {
    dispatch(populateDetails({ candidateId }));
  }, [candidateId]);

  if (!makingOffer && !hasError && offerMade){
    const offerConfirmationUrl = `/offerconfirmation`;
    return <Redirect to={offerConfirmationUrl} />;
  }

  return (
    <>
      <Formik
        validationSchema={getMakeOfferSchema}
        onSubmit={(values) => {
          dispatch(makeOffer(values));
          setOfferMade(true);
        }}
        initialValues={{
          employerId: employerId,
          candidateId: candidateId,
          jobTitle: "",
          jobDescription: "",
          startDate: "",
          proposedHourlyRate: "",
        }}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          isInvalid,
          errors,
        }) => (
          <IAAMForm onSubmit={handleSubmit}>
            <HeaderText level={4} data-cy={'headerText'}>
              <img
                alt="arrow-left"
                onClick={handleGoBack}
                src={arrowLineLeft}
                width={16}
              ></img>{" "}
              Make an offer
            </HeaderText>

            <FormGroup controlId="formProposedHourlyRate">
              <FormLabel required>Hourly Rate</FormLabel>
              <FormControl
                type="number"
                name="proposedHourlyRate"
                value={values.proposedHourlyRate}
                onChange={handleChange}
                isInvalid={
                  touched.proposedHourlyRate && (errors.proposedHourlyRate || offerErrors.proposedHourlyRate)
                }
                data-cy={'rate'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'rateErrors'}>
                {errors.proposedHourlyRate || offerErrors.proposedHourlyRate}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formJobTitle">
              <FormLabel required>Job Title</FormLabel>
              <FormControl
                type="text"
                name="jobTitle"
                value={values.jobTitle}
                onChange={handleChange}
                isInvalid={touched.jobTitle && (errors.jobTitle || offerErrors.jobTitle)}
                data-cy={'jobTitle'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'jobTitleErrors'}>
                {errors.jobTitle || offerErrors.jobTitle}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formStartDate">
              <FormLabel required>Start Date</FormLabel>
              <FormControl
                type="text"
                name="startDate"
                value={values.startDate}
                onChange={handleChange}
                isInvalid={touched.startDate && (errors.startDate || offerErrors.startDate)}
                data-cy={'startDate'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'startDateErrors'}>
                {errors.startDate || offerErrors.startDate}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formJobDescription">
              <FormLabel required>
                Job Description
                &nbsp;<CharactersLeft as="sub" maxLength={500}
                                      value={values.jobDescription} />
              </FormLabel>
              <FormControl
                as="textarea"
                rows="5"
                name="jobDescription"
                value={values.jobDescription}
                onChange={handleChange}
                isInvalid={touched.jobDescription && (errors.jobDescription || offerErrors.jobDescription)}
                data-cy={'jobDescription'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'jobDescriptionErrors'}>
                {errors.jobDescription || offerErrors.jobDescription}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup>
              <Button disabled={makingOffer} variant="primary" type="submit" data-cy={'makeOffer'}>
                Make Offer!
              </Button>
            </FormGroup>

            <Alert variant={'danger'} show={!makingOffer && !!offerErrors._ && offerMade}>{offerErrors._}</Alert>
          </IAAMForm>
        )}
      </Formik>
    </>
  );
};

export default MakeOffer;
