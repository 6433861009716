import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAuthentication } from '../auth/selectors';
import apiClient from '../utils/api';

const FreeSubscriptionButton = ({ subscriptionPlan, promoCode, onPaymentSuccess }) => {
  const { access_token } = useSelector(getAuthentication);
  console.log(subscriptionPlan);
  useEffect(() => {
    const { planId } = subscriptionPlan;
    const payload = { planId, promoCode };
    apiClient.post(
      'v1/payment/Free/createPaymentSubscription', payload, {
        headers: {
          'Authorization': 'Bearer ' + access_token,
        },
      })
    .then(onPaymentSuccess, console.error);
  }, [subscriptionPlan]);
  return (
    <div>Activating...</div>
  );
};

FreeSubscriptionButton.defaultProps = {
  onPaymentSuccess: () => {},
};

export default FreeSubscriptionButton;
