import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import { rootSaga } from './rootSaga';

const stateStorageKey = 'iaam_state';

const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
// Middleware: Redux Saga
const sagaMiddleware = createSagaMiddleware();
let loadedState;
try {
  const { auth, ...rest } = JSON.parse(window.sessionStorage.getItem(stateStorageKey) || '{}');
  loadedState = { auth };
} catch (err) {}

const store = createStore(
  rootReducer,
  loadedState,
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware
    )
  )
);

store.subscribe(() => {
  const stateToStore = store.getState();
  window.sessionStorage.setItem(stateStorageKey, JSON.stringify(stateToStore));
});

// Middleware: Redux Saga
sagaMiddleware.run(rootSaga);

export default store;
