import React, { useState, useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { getVideoInfo, captureThumb } from './get-video-info';
import {
  ReactVideoRecorderDataIssueError,
  ReactVideoRecorderRecordedBlobsUnavailableError,
  ReactVideoRecorderDataAvailableTimeoutError,
  ReactVideoRecorderMediaRecorderUnavailableError
} from './custom-errors';
import DisconnectedView from './DisconnectedView';
import VideoInputView from './VideoInputView';
import UnsupportedView from './UnsupportedView';
import ErrorView from './ErrorView';
import LoadingView from './LoadingView';
import ActionsView from './ActionsView';
import CameraView from './CameraView';

const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    minHeight: '300px', // TODO review
    backgroundColor: '#000',
    color: 'white',
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'inherit',
    },
  },
});

const MIME_TYPES = [
  'video/webm;codecs="vp8,opus"',
  'video/webm;codecs=h264',
  'video/webm;codecs=vp9',
  'video/webm'
];

const VideoRecorder = React.forwardRef(({
  showReplayControls = false,
  replayVideoAutoplayAndLoopOff = false,
  DisconnectedComponent = DisconnectedView,
  VideoInputComponent = VideoInputView,
  UnsupportedComponent = UnsupportedView,
  ErrorComponent = ErrorView,
  LoadingComponent = LoadingView,
  ActionsComponent = ActionsView,
  useVideoInput = false,
  isFlipped = true,
  isOnInitially = false,
  onRecordingComplete = () => {},
  onError,
  onTurnOnCamera,
  onTurnOffCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onPauseRecording,
  onResumeRecording,
  onStartReplaying,
  onStopReplaying,
  onCameraOn,
  constraints = { audio: true, video: true },
  countdownTime = 3000,
  timeLimit,
  chunkSize = 250,
  dataAvailableTimeout = 500,
  mimeType,
}, ref) => {
  const classes = useStyles({});

  const [state, setState] = useState({
    isRecording: false,
    isCameraOn: false,
    isConnecting: false,
    isReplayingVideo: false,
    isReplayVideoMuted: true,
    thereWasAnError: false,
    error: null,
    streamIsReady: false,
    isInlineRecordingSupported: null,
    isVideoInputSupported: null,
    stream: undefined
  });

  const videoInputRef = useRef();
  const replayVideoRef = useRef();
  const cameraVideoRef = useRef();
  const mediaSourceRef = useRef(); // TODO remove, not used
  const mediaRecorderRef = useRef();
  const streamRef = useRef();
  const thumbnailRef = useRef();
  const recordedBlobsRef = useRef([]);
  const startedAtRef = useRef();
  const timeLimitTimeoutRef = useRef();

  ref.current = { get cameraVideo() { return cameraVideoRef.current; } };

  const handleError = (err) => {
    console.error('Captured error', err);

    clearTimeout(timeLimitTimeoutRef.current);

    if (onError) {
      onError(err);
    }

    setState(state => ({
      ...state,
      isConnecting: state.isConnecting && false,
      isRecording: false,
      thereWasAnError: true,
      error: err
    }));

    if (state.isCameraOn) {
      turnOffCamera();
    }
  };
  const handleVideoSelected = async (e) => {
    if (state.isReplayingVideo) {
      setState(state => ({
        ...state,
        isReplayingVideo: false
      }));
    }

    const files = e.target.files || e.dataTransfer.files;
    if (files.length === 0) return;

    const startedAt = new Date().getTime();
    const video = files[0];

    e.target.value = null;

    const extension = video.type === 'video/quicktime' ? 'mov' : undefined;

    try {
      const { duration, thumbnail } = await getVideoInfo(video);
      setState(state => ({
        ...state,
        isRecording: false,
        isReplayingVideo: true,
        isReplayVideoMuted: true,
        videoBlob: video,
        videoUrl: window.URL.createObjectURL(video)
      }));
      onRecordingComplete(
        video,
        startedAt,
        thumbnail,
        duration,
        extension
      );
    } catch (err) {
      handleError(err);
    }
  };
  const handleReplayVideoClick = () => {
    if (replayVideoRef.current.paused && !showReplayControls) {
      replayVideoRef.current.play();
    }

    // fixes bug where seeking control during autoplay is not available until the video is almost completely played through
    if (!replayVideoAutoplayAndLoopOff) {
      setState(state => ({
        ...state,
        isReplayVideoMuted: !state.isReplayVideoMuted
      }));
    }
  };
  const handleDurationChange = () => {
    if (showReplayControls) {
      replayVideoRef.current.currentTime = 1000000;
    }
  };
  const handleOpenVideoInput = () => {
    if (onOpenVideoInput) {
      onOpenVideoInput();
    }

    videoInputRef.current.value = null;
    videoInputRef.current.click();
  };
  const handleSuccess = (stream) => {
    streamRef.current = stream;
    setState(state => ({
      ...state,
      isCameraOn: true,
      stream: stream
    }));
    if (onCameraOn) {
      onCameraOn();
    }

    if (window.URL) {
      cameraVideoRef.current.srcObject = stream;
    } else {
      cameraVideoRef.current.src = stream;
    }

    // there is probably a better way
    // but this makes sure the start recording button
    // gives the stream a couple miliseconds to be ready
    setTimeout(() => {
      setState(state => ({
        ...state,
        isConnecting: false,
        streamIsReady: true
      }));
    }, 200);
  };
  const getMimeType = () => {
    if (mimeType) {
      return mimeType;
    }

    const _mimeType = window.MediaRecorder.isTypeSupported
      ? MIME_TYPES.find(window.MediaRecorder.isTypeSupported)
      : 'video/webm';

    return _mimeType || '';
  };
  const handleStop = (event) => {
    const endedAt = new Date().getTime();

    const recordedBlobs = recordedBlobsRef.current;
    if (!recordedBlobs || recordedBlobs.length <= 0) {
      const error = new ReactVideoRecorderRecordedBlobsUnavailableError(event);
      console.error(error.message, event);
      handleError(error);
      return;
    }

    clearTimeout(timeLimitTimeoutRef.current);

    const videoBlob =
      recordedBlobs.length === 1
        ? recordedBlobs[0]
        : new window.Blob(recordedBlobs, {
          type: getMimeType()
        });

    const thumbnailBlob = thumbnailRef.current;
    const duration = endedAt - startedAtRef.current;

    // if this gets executed too soon, the last chunk of data is lost on FF
    mediaRecorderRef.current.ondataavailable = null;

    setState(state => ({
      ...state,
      isRecording: false,
      isReplayingVideo: true,
      isReplayVideoMuted: true,
      videoBlob,
      videoUrl: window.URL.createObjectURL(videoBlob)
    }));

    turnOffCamera();

    onRecordingComplete(
      videoBlob,
      startedAtRef.current,
      thumbnailBlob,
      duration
    );
  };
  const handleDataIssue = (event) => {
    const error = new ReactVideoRecorderDataIssueError(event);
    console.error(error.message, event);
    handleError(error);
    return false;
  };
  const isDataHealthOK = (event) => {
    if (!event.data) return handleDataIssue(event);

    const dataCheckInterval = 2000 / chunkSize;

    const recordedBlobs = recordedBlobsRef.current;
    // in some browsers (FF/S), data only shows up
    // after a certain amount of time ~everyt 2 seconds
    const blobCount = recordedBlobs.length;
    if (blobCount > dataCheckInterval && blobCount % dataCheckInterval === 0) {
      const blob = new window.Blob(recordedBlobs, {
        type: getMimeType()
      });
      if (blob.size <= 0) return handleDataIssue(event);
    }

    return true;
  };
  const handleDataAvailable = (event) => {
    if (isDataHealthOK(event)) {
      recordedBlobsRef.current.push(event.data);
    }
  };
  const startRecording = () => {
    captureThumb(cameraVideoRef.current).then(thumbnail => {
      thumbnailRef.current = thumbnail;

      recordedBlobsRef.current = [];
      const options = {
        mimeType: getMimeType()
      };

      try {
        setState(state => ({
          ...state,
          isRunningCountdown: false,
          isRecording: true
        }));
        startedAtRef.current = new Date().getTime();
        const mediaRecorder = new window.MediaRecorder(streamRef.current, options);
        mediaRecorder.addEventListener('stop', handleStop);
        mediaRecorder.addEventListener('error', handleError);
        mediaRecorder.addEventListener('dataavailable', handleDataAvailable);

        mediaRecorder.start(chunkSize); // collect 10ms of data
        mediaRecorderRef.current = mediaRecorder;

        if (timeLimit) {
          timeLimitTimeoutRef.current = setTimeout(() => {
            handleStopRecording();
          }, timeLimit);
        }

        // mediaRecorder.ondataavailable should be called every 10ms,
        // as that's what we're passing to mediaRecorder.start() above
        if (Number.isInteger(dataAvailableTimeout)) {
          setTimeout(() => {
            if (recordedBlobsRef.current.length === 0) {
              handleError(
                new ReactVideoRecorderDataAvailableTimeoutError(
                  dataAvailableTimeout
                )
              );
            }
          }, dataAvailableTimeout);
        }
      } catch (err) {
        console.error("Couldn't create MediaRecorder", err, options);
        handleError(err);
      }
    });
  };
  const handleStartRecording = () => {
    if (onStartRecording) {
      onStartRecording();
    }

    setState(state => ({
      ...state,
      isRunningCountdown: true,
      isReplayingVideo: false
    }));

    setTimeout(() => startRecording(), countdownTime);
  };
  const handleStopRecording = () => {
    if (onStopRecording) {
      onStopRecording();
    }

    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      handleError(new ReactVideoRecorderMediaRecorderUnavailableError());
      return;
    }

    mediaRecorder.stop();
  };
  const handlePauseRecording = () => {
    if (onPauseRecording) {
      onPauseRecording();
    }

    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      handleError(new ReactVideoRecorderMediaRecorderUnavailableError());
      return;
    }

    mediaRecorder.pause();
  };
  const handleResumeRecording = () => {
    if (onResumeRecording) {
      onResumeRecording();
    }

    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      handleError(new ReactVideoRecorderMediaRecorderUnavailableError());
      return;
    }

    mediaRecorder.resume();
  };
  const handleStartReplaying = () => {
    if (onStartReplaying) {
      onStartReplaying();
    }

    replayVideoRef.current.play();
  };
  const handleStopReplaying = () => {
    if (onStopReplaying) {
      onStopReplaying();
    }

    if (useVideoInput && isOnInitially) {
      return handleOpenVideoInput();
    }

    setState(state => ({
      ...state,
      isReplayingVideo: false
    }));

    if (state.isInlineRecordingSupported && isOnInitially) {
      turnOnCamera();
    } else if (state.isVideoInputSupported && isOnInitially) {
      handleOpenVideoInput();
    }
  };
  const turnOnCamera = () => {
    if (onTurnOnCamera) {
      onTurnOnCamera();
    }

    setState(state => ({
      ...state,
      isConnecting: true,
      isReplayingVideo: false,
      thereWasAnError: false,
      error: null
    }));

    const fallbackContraints = {
      audio: true,
      video: true
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .catch(err => {
        // there's a bug in chrome in some windows computers where using `ideal` in the constraints throws a NotReadableError
        if (
          err.name === 'NotReadableError' ||
          err.name === 'OverconstrainedError'
        ) {
          console.warn(
            `Got ${
              err.name
            }, trying getUserMedia again with fallback constraints`
          );
          return navigator.mediaDevices.getUserMedia(fallbackContraints);
        }
        throw err;
      })
      .then(handleSuccess)
      .catch(handleError);
  };
  const turnOffCamera = () => {
    if (onTurnOffCamera) {
      onTurnOffCamera();
    }

    const stream = streamRef.current;
    stream && stream.getTracks().forEach(stream => stream.stop());
    setState(state => ({
      ...state,
      isCameraOn: false
    }));
    //clearInterval(inactivityTimer)
  };
  const tryToUnmuteReplayVideo = () => {
    const video = replayVideoRef.current;
    video.muted = false;

    const playPromise = video.play();
    if (!playPromise) {
      video.muted = true;
      return;
    }

    playPromise
      .then(() => {
        setState(state => ({ ...state, isReplayVideoMuted: false }));
        // fixes bug where seeking control during autoplay is not available until the video is almost completely played through
        if (replayVideoAutoplayAndLoopOff) {
          video.pause();
          video.loop = false;
        }
      })
      .catch(err => {
        console.warn('Could not autoplay replay video', err);
        video.muted = true;
        return video.play();
      })
      .catch(err => {
        console.warn('Could play muted replay video after failed autoplay', err);
      });
  };

  useEffect(() => {
    const isInlineRecordingSupported =
      !!window.MediaSource && !!window.MediaRecorder && !!navigator.mediaDevices;

    const isVideoInputSupported =
      document.createElement('input').capture !== undefined;

    if (isInlineRecordingSupported) {
      mediaSourceRef.current = new window.MediaSource();
    }

    setState(state => ({
      ...state,
      isInlineRecordingSupported,
      isVideoInputSupported,
    }));

    return () => {
      turnOffCamera();
    };
  }, []);
  useEffect(() => {
    if (
      state.isInlineRecordingSupported === null ||
      state.isVideoInputSupported === null
    ) return;
    if (useVideoInput && isOnInitially) {
      handleOpenVideoInput();
    } else if (state.isInlineRecordingSupported && isOnInitially) {
      turnOnCamera();
    } else if (state.isVideoInputSupported && isOnInitially) {
      handleOpenVideoInput();
    }
  }, [state.isInlineRecordingSupported, state.isVideoInputSupported]);
  useEffect(() => {
    if (replayVideoRef.current && state.isReplayingVideo) {
      tryToUnmuteReplayVideo();
    }
  }, [state.isReplayingVideo]);

  const {
    isVideoInputSupported,
    isInlineRecordingSupported,
    thereWasAnError,
    isRecording,
    isCameraOn,
    streamIsReady,
    isConnecting,
    isRunningCountdown,
    isReplayingVideo,
    isReplayVideoMuted,
    error,
    videoUrl,
  } = state;
  return (
    <div className={classes.wrapper}>
      <CameraView {...({
        replayVideoAutoplayAndLoopOff,
        DisconnectedComponent,
        VideoInputComponent,
        UnsupportedComponent,
        ErrorComponent,
        LoadingComponent,
        useVideoInput,
        isFlipped,
        //
        isVideoInputSupported,
        isReplayingVideo,
        isInlineRecordingSupported,
        thereWasAnError,
        error,
        isCameraOn,
        isConnecting,
        isReplayVideoMuted,
        videoUrl,
        //
        videoInputRef,
        replayVideoRef,
        cameraVideoRef,
        handleVideoSelected,
        handleReplayVideoClick,
        handleDurationChange,
      })}/>
      <ActionsComponent {...({
        isVideoInputSupported,
        isInlineRecordingSupported,
        thereWasAnError,
        isRecording,
        isCameraOn,
        streamIsReady,
        isConnecting,
        isRunningCountdown,
        isReplayingVideo,
        isReplayVideoMuted,
        countdownTime,
        timeLimit,
        showReplayControls,
        replayVideoAutoplayAndLoopOff,
        useVideoInput,
        onTurnOnCamera: turnOnCamera,
        onTurnOffCamera: turnOffCamera,
        onOpenVideoInput: handleOpenVideoInput,
        onStartRecording: handleStartRecording,
        onStopRecording: handleStopRecording,
        onPauseRecording: handlePauseRecording,
        onResumeRecording: handleResumeRecording,
        onStartReplaying: handleStartReplaying,
        onStopReplaying: handleStopReplaying
      })}/>
    </div>
  );
});

export default VideoRecorder;
