import React, {useState, useEffect} from "react";
import { createUseStyles, useTheme } from 'react-jss';
import { Modal } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import Button from '../../components/Button';
import HeaderText from '../../components/HeaderText';
import { isHospitalityNetwork } from '../../config';
import tosMarkdown from './tos.md';
import tosHospitalityMarkdown from './tos-hospitality.md';

const tosUrl = isHospitalityNetwork ? tosHospitalityMarkdown : tosMarkdown;

const useStyles = createUseStyles(theme => {
  termsOfService: {}
});

const renderers = {
  heading: HeaderText
};

const TermsOfService = ({ onHide, show }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [md, setMd] = useState();
  useEffect(function() {
    if (md) return;
    fetch(tosUrl)
      .then(response => response.text())
      .then(data => setMd(data));
  }, [md]);
  return <Modal size="xl" onHide={onHide} show={show} className={classes.termsOfService}>
    <Modal.Header closeButton>
      <Modal.Title>IAAM Candidate Terms Of Service</Modal.Title>
    </Modal.Header>
    <Modal.Body data-cy={'candidateTermsOfService'}>
      <ReactMarkdown source={md} renderers={renderers}/>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide} data-cy={'close'}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>;
};

export default TermsOfService;
